import React, { useEffect, useState } from 'react'

import styles from './Loading.module.css'
// @ts-ignore
import RawFormattedMessage from './RawFormattedMessage'
import { trackError } from '../tracking/error'

const assetPrefix = process.env.ASSET_PREFIX || ''

export const ErrorPage = () => (
  <section className={styles.root}>
    <header>
      <img
        src={`${assetPrefix}/static/info.svg`}
        className={styles.reloadIcon}
      />
      <h1>Couldn't load the page</h1>
    </header>
    <p>
      This is probably due to a technical problem. Your information is not lost.
    </p>
    <button
      className={styles.reload}
      type='submit'
      onClick={() => window.location.reload()}
    >
      <RawFormattedMessage id='reload' />
    </button>
  </section>
)

const loading = (
  <section id='loading' className={styles.root}>
    <div className={styles.spinner}>
      <div className={styles.spinnerElement} />
      <div className={styles.spinnerElement} />
      <div className={styles.spinnerElement} />
      <div className={styles.spinnerElement} />
    </div>
  </section>
)

export default function Loading (_: { retry?: boolean }) {
  const [showTimeoutScreen, setShowTimeoutScreen] = useState(false)
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setShowTimeoutScreen(true)
    }, 20 * 1000)
    return () => window.clearTimeout(timer)
  }, [])

  if (showTimeoutScreen) {
    trackError('Loading timeout')
    return <ErrorPage />
  }

  return loading
}
