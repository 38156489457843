// @flow
import { omit, set, setIn, update, updateIn } from 'timm'

import { handleResponse } from './helpers/replaceAttribute'
import type { RequestResponseMiddleware } from '../../../types/JsonApi'

export const serviceClassRes: RequestResponseMiddleware = {
  name: 'serviceClassRes',
  res: function serviceClassRes (payload) {
    return handleResponse(payload, {
      from: 'flavor',
      to: 'service_class',
      value: value => value
    })
  }
}
