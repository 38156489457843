import fetch from 'isomorphic-fetch'
import { stringify } from 'qs'
import { Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { merge } from 'timm'
import { ActionType, createStandardAction, getType } from 'typesafe-actions'

// @ts-ignore
import { actions as cookieActions } from '../api/cookies'
import {
  actionCreators as flashMessageActionCreators,
  MessageTypeFailure,
  MessageTypeSuccess
  // @ts-ignore
} from '../flashMessage'
// @ts-ignore
import getEnvVariable from '../helpers/env'
import { JsonApiError } from 'types/JsonApi'

// Constants
const flashMessages = {
  successLogin: {
    id: 'login.success'
  },
  successLogout: {
    id: 'logout.success'
  },
  failureLogin: {
    id: 'login.failure'
  },
  sessionExpired: {
    id: 'login.expired'
  }
}

// Actions
// Action type constants are redundant when using typesafe-actions,
// but other files import this actions object, so it stays here for now.
export const actions = {
  LOAD_AUTH_TOKEN_SUCCESS: 'login/LOAD_AUTH_TOKEN_SUCCESS',
  LOAD_AUTH_TOKEN_REQUEST: 'login/LOAD_AUTH_TOKEN_REQUEST',
  LOAD_AUTH_TOKEN_FAILURE: 'login/LOAD_AUTH_TOKEN_FAILURE',
  SESSION_EXPIRED: 'login/SESSION_EXPIRED',
  LOGOUT: 'login/LOGOUT'
}

// Model and initial state
export interface LoginState {
  readonly error: boolean
  readonly isFetching: boolean
  readonly isLoggedIn: boolean
  readonly accessToken: string
}

export const initialState: LoginState = {
  error: false,
  isFetching: false,
  isLoggedIn: false,
  accessToken: ''
}

// Regular actions
type LoginSuccess = {
  result: {
    access_token: string
    token_type: string
    expires_in: number
  }
}

type LoginFailure = {
  errors: Array<{ message: string; description: string }>
}

const actionCreatorsSync = {
  // loadAuthTokenRequest: createAction(
  //   'login/LOAD_AUTH_TOKEN_REQUEST',
  //   resolve =>
  //     () => resolve({}, { isLoading: true }, false)
  // ),
  loadAuthTokenRequest: createStandardAction(
    'login/LOAD_AUTH_TOKEN_REQUEST'
  ).map(() => ({
    payload: {},
    meta: {
      isLoading: true
    },
    error: false
  })),
  // loadAuthTokenSuccess: createAction(
  //   'login/LOAD_AUTH_TOKEN_SUCCESS',
  //   resolve =>
  //     ({ result }) => resolve(result.payload, { isLoading: true }, false)
  //   ),
  loadAuthTokenSuccess: createStandardAction(
    'login/LOAD_AUTH_TOKEN_SUCCESS'
  ).map(({ result: payload }: LoginSuccess) => ({
    payload,
    meta: {
      isLoggedIn: true
    },
    error: false
  })),
  // loadAuthTokenFailure: createAction(
  //   'login/LOAD_AUTH_TOKEN_FAILURE',
  //   resolve =>
  //     ({ errors }) => resolve(errors.payload, {}, true)
  // ),
  loadAuthTokenFailure: createStandardAction(
    'login/LOAD_AUTH_TOKEN_FAILURE'
  ).map(({ errors: payload }: LoginFailure) => ({
    payload,
    meta: {},
    error: true
  })),
  // const logoutAction  = createAction(
  //   'login/LOGOUT',
  //   resolve => () => resolve({}, { isLoggedIn: false }, false)
  // )
  logoutAction: createStandardAction('login/LOGOUT').map(() => ({
    error: false,
    payload: {},
    meta: {
      isLoggedIn: false
    }
  })),
  // const sessionExpiredAction  = createAction(
  //   'login/SESSION_EXPIRED',
  //   resolve => (error: JsonApiError) =>
  //     resolve(error, {
  //       isFetching: false,
  //       request: {}
  //     },
  //       true)
  // )
  sessionExpiredAction: createStandardAction('login/SESSION_EXPIRED').map(
    (error: JsonApiError) => ({
      error: true,
      payload: error,
      meta: {
        isFetching: false,
        request: {}
      }
    })
  )
}

export type LoginActions = ActionType<typeof actionCreatorsSync>

// Async actions
type ThunkResult<R> = ThunkAction<R, unknown, unknown, LoginActions>

const actionCreatorsAsync = {
  logout: (): ThunkResult<void> => async dispatch => {
    dispatch(actionCreatorsSync.logoutAction())
    dispatch(
      // @ts-ignore
      flashMessageActionCreators.addFlashMessage(
        MessageTypeSuccess,
        flashMessages.successLogout
      )
    )
  },
  sessionExpired: (error: JsonApiError) => {
    const flashMessageAction = flashMessageActionCreators.addFlashMessage(
      MessageTypeFailure,
      flashMessages.sessionExpired
    )
    return [actionCreatorsSync.sessionExpiredAction(error), flashMessageAction]
  },
  loadAuthToken: (): ThunkResult<void> => {
    const { username, password } = getFormUsernamePassword()
    return dispatch => {
      dispatch(actionCreators.loadAuthTokenRequest())
      fetchTokenEndpoint(username, password).then(async response => {
        let jsonResponse
        try {
          jsonResponse = await response.json()
        } catch (error) {
          return error
        }
        handleLoadAuthTokenResponse({
          isSuccess: response.status < 400,
          dispatch,
          jsonResponse
        })
      })
    }
  },
  loginWithAuthToken: () => {
    const payload = getAccessTokenFromQuery()
    return (dispatch: Dispatch<any>) => {
      dispatch(actionCreators.loadAuthTokenSuccess(payload))
    }
  }
}
export const actionCreators = { ...actionCreatorsSync, ...actionCreatorsAsync }

// Reducer
const reducer = (
  state: typeof initialState = initialState,
  action: LoginActions
) => {
  switch (action.type) {
    case getType(actionCreators.loadAuthTokenRequest):
      return merge(state, {
        error: false,
        isFetching: true
      })
    case getType(actionCreators.loadAuthTokenSuccess):
    case cookieActions.READ_AUTH_TOKEN_COOKIE_SUCCESS: {
      return merge(state, {
        error: false,
        isFetching: false,
        isLoggedIn: true,
        accessToken: (action.payload as LoginSuccess['result']).access_token
      })
    }
    case getType(actionCreators.loadAuthTokenFailure):
    case cookieActions.READ_AUTH_TOKEN_COOKIE_FAILURE:
      return merge(state, {
        error: true,
        isFetching: false,
        isLoggedIn: false
      })
    case getType(actionCreators.logoutAction):
    case getType(actionCreators.sessionExpiredAction): {
      deleteTokenEndpoint(state.accessToken)
      return merge(state, {
        error: false,
        isFetching: false,
        isLoggedIn: false,
        accessToken: ''
      })
    }
    default:
      return state
  }
}

// Side effects
export function fetchTokenEndpoint (
  username: string,
  password: string
): Promise<Response> {
  const body = stringify({
    client_id: getEnvVariable('HADES_CLIENT_ID'),
    username,
    password,
    grant_type: 'implicit',
    resource_owner_type: 'driver'
  })
  return fetch(`${getEnvVariable('ATHENA_ENDPOINT')}/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body
  })
}

export function deleteTokenEndpoint (accessToken: string): Promise<Response> {
  return fetch(`${getEnvVariable('ATHENA_ENDPOINT')}/oauth/token`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

function getAccessTokenFromQuery () {
  const params = new URLSearchParams(window.location.search)
  return {
    result: {
      access_token: params.get('access-token')!,
      expires_in: 3600,
      token_type: 'bearer'
    }
  }
}

function getFormUsernamePassword (): { username: string; password: string } {
  const usernameElement = document.getElementById('email')
  const passwordElement = document.getElementById('password')
  if (
    !(usernameElement instanceof HTMLInputElement) ||
    !(passwordElement instanceof HTMLInputElement)
  ) {
    throw new Error('Username/password are not input elements (?!)')
  }
  return {
    username: usernameElement.value,
    password: passwordElement.value
  }
}

function handleLoadAuthTokenResponse ({
  isSuccess,
  dispatch,
  jsonResponse
}: {
  isSuccess: boolean
  dispatch: Dispatch<any>
  jsonResponse: any
}) {
  const loginAction = isSuccess
    ? actionCreators.loadAuthTokenSuccess(jsonResponse)
    : actionCreators.loadAuthTokenFailure(jsonResponse)
  dispatch(loginAction)
  if (!isSuccess) {
    dispatch(
      flashMessageActionCreators.addFlashMessage(
        MessageTypeFailure,
        flashMessages.failureLogin
      )
    )
  }
}

export default reducer
