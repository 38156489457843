import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
// @ts-ignore
import { accessTokenCookieName } from '../api/cookies'
import getEnvVariable from '../helpers/env'
import { trackError } from '../tracking/error'

type FetchState<T> = {
  isLoading: boolean
  data: T | null
  error: Error | null
  reload: () => void
}

type FetchOptions = Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string>
  query?: Record<string, string>
}

export type BaseResponse<T> = {
  data: T
}

export type ListResponse<T> = {
  data: Array<Resource<T>>
  meta: Meta
}

export type PaginatedListResponse<T> = {
  results: T[]
  pagination: Pagination
}

export type Pagination = {
  offset: number
  limit: number
}

export type ListResponseWithIncluded<T, I> = ListResponse<T> & {
  included?: Array<Resource<I>>
}

export type SingleResponse<T> = BaseResponse<Resource<T>>

export type Meta = {
  record_count: number
  page_count: number
}

export type Resource<T> = {
  id: string
  attributes: T
  relationships?: { [relatedEntityName: string]: Relationship }
}

export type Relationship = {
  data: RelationshipData
}

export type RelationshipData = {
  id: string
}

export type ApiListResponse<T> = {
  results: T[]
}

export function useFetch<T> (
  url: string | null,
  options: FetchOptions = {}
): FetchState<T> {
  const [isLoading, setIsLoading] = useState(url != null)
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [reloadKey, setReloadKey] = useState(0)

  useEffect(() => {
    const doRequest = async () => {
      try {
        setIsLoading(true)
        setError(null)
        const responseData = await sendRequest(url!, options)
        setData(await responseData.json())
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    }

    if (url != null) {
      doRequest()
    }
  }, [url, reloadKey])

  return {
    isLoading,
    data,
    error,
    reload: () => setReloadKey(Date.now())
  }
}

export async function sendRequest (url: string, options: FetchOptions = {}) {
  options.headers = options.headers || {}

  const accessToken = Cookies.get(accessTokenCookieName)
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`
  }

  // TODO: remove once we don't use hades anymore
  if (url.includes('/hades/')) {
    options.headers.Accept = 'application/vnd.api+json'

    if (options.body) {
      options.headers['Content-Type'] = 'application/vnd.api+json'
    }
  } else {
    if (options.body) {
      options.headers['Content-Type'] = 'application/json'
    }
  }

  const response = await fetch(url, options)
  if (!response.ok) {
    const error = await HttpResponseError.create(response)

    if (response.status === 401) {
      console.log('response status was 401. redirecting to login')
      Cookies.remove(accessTokenCookieName)
      window.location.href = '/'
    }

    throw error
  }
  return response
}

export class HttpResponseError extends Error {
  status: number
  body: any

  constructor (status: number, body: any) {
    super('HTTP request failed with status ' + status)
    this.status = status
    this.body = body
  }

  static async create (response: Response) {
    try {
      let body
      if (response.headers.get('Content-Type')?.includes('json')) {
        body = await response.json()
      } else {
        body = await response.text()
      }
      return new HttpResponseError(response.status, body)
    } catch (e) {
      trackError('Failed to extract response body', e)
      return new HttpResponseError(response.status, {})
    }
  }
}

export function constructAthenaUrl (path: string, query: any = {}) {
  return `${getEnvVariable('ATHENA_ENDPOINT')}${constructUrl(path, query)}`
}

export function constructApiUrl (path: string, query: any = {}) {
  return `${getEnvVariable('API_ENDPOINT')}${constructUrl(path, query)}`
}

export function isHttpResponseError (error: any, statusCode: number) {
  return error instanceof HttpResponseError && error.status === statusCode
}

function constructUrl (path: string, query: any = {}) {
  if (Object.keys(query).length > 0) {
    const queryString = new URLSearchParams(query).toString()
    return `${path}?${queryString}`
  }
  return path
}
