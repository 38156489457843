import Cookies from 'js-cookie'
import { merge } from 'timm'
// $FlowFixMe TypeScript import
import { actions as loginActions } from '../login'

// Constants
export const accessTokenCookieName = '__bl_pp__'

// Actions
export const actions = {
  READ_AUTH_TOKEN_COOKIE_SUCCESS: 'READ_AUTH_TOKEN_COOKIE_SUCCESS',
  READ_AUTH_TOKEN_COOKIE_FAILURE: 'READ_AUTH_TOKEN_COOKIE_FAILURE'
}

// Reducer
export default function reducer (state = {}, { payload, type }: Action) {
  switch (type) {
    case loginActions.LOAD_AUTH_TOKEN_SUCCESS:
    case actions.READ_AUTH_TOKEN_COOKIE_SUCCESS: {
      if (payload && payload.access_token && payload.expires_in) {
        Cookies.set(accessTokenCookieName, payload.access_token, {
          expires: payload.expires_in / 60 / 60 / 24 // expires_in is returned in seconds, while Cookies accepts a number of days
        })
      }
      return state
    }
    case loginActions.LOGOUT:
    case actions.READ_AUTH_TOKEN_COOKIE_FAILURE: {
      Cookies.remove(accessTokenCookieName)
      return state
    }
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  readAuthTokenCookie () {
    const token = Cookies.get(accessTokenCookieName)
    const baseAction = {
      error: true,
      meta: {}
    }
    if (token) {
      return merge(baseAction, {
        type: actions.READ_AUTH_TOKEN_COOKIE_SUCCESS,
        payload: {
          access_token: token
        }
      })
    }
    return merge(baseAction, {
      type: actions.READ_AUTH_TOKEN_COOKIE_FAILURE,
      payload: {
        errors: [
          {
            code: 'auth_token_cookie_not_found',
            detail: 'Cookie not found'
          }
        ]
      }
    })
  }
}
