// @flow
import JsonApi from 'devour-client'
import type { JsonApiInstance } from 'devour-client'
import { set, setIn, update } from 'timm'

import typeof { initialState as APIState } from './index'
import athenaErrorMiddleware from './middlewares/athenaError'
import errorsMiddleware from './middlewares/errors'
import {
  flightDesignatorReq,
  flightDesignatorRes
} from './middlewares/flightDesignator'
import { priceInCentsReq, priceInCentsRes } from './middlewares/priceInCents'
import { serviceClassRes } from './middlewares/serviceClass'
// $FlowIgnoreMe TS import
import { statusCodeRes } from './middlewares/statusCode'
import updatedAtMiddleware from './middlewares/updatedAt'
import type { AllowedIncludeEntity, Entity } from '../../types/Entity'

const jsonApiPrefix = 'hades'
let jsonApiClient: ?JsonApiInstance = null
let jsonApiModels: Array<{
  entityName: Entity | AllowedIncludeEntity,
  model: Object,
  options?: Object
}> = []

function setMissingJsonApiModels (
  jsonApiClient: JsonApiInstance
): JsonApiInstance {
  if (jsonApiModels.length > 0) {
    jsonApiModels = jsonApiModels.map(jsonApiModel => {
      jsonApiClient.define(
        jsonApiModel.entityName,
        jsonApiModel.model,
        jsonApiModel.options
      )
      return set(jsonApiModel, 'hasBeenAdded', true)
    })
  }
  return jsonApiClient
}
function handleAuthentication ({
  accessToken,
  jsonApiClient,
  userUuid
}): JsonApiInstance {
  if (process.env.NODE_ENV === 'test' && process.env.IS_PACT) {
    jsonApiClient.headers['X-USER-UUID'] = userUuid || ''
  } else {
    jsonApiClient.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return jsonApiClient
}

export function createJsonApiClient ({
  accessToken,
  apiUrl,
  userUuid
}: APIState): ?JsonApiInstance {
  if (!apiUrl && !accessToken) {
    return null
  }
  if (!jsonApiClient) {
    jsonApiClient = new JsonApi({
      apiUrl
    })
    jsonApiClient.insertMiddlewareBefore('axios-request', flightDesignatorReq)
    jsonApiClient.insertMiddlewareBefore('axios-request', priceInCentsReq)
    jsonApiClient.replaceMiddleware('errors', errorsMiddleware)
    jsonApiClient.insertMiddlewareAfter('errors', athenaErrorMiddleware)
    jsonApiClient.insertMiddlewareBefore('response', statusCodeRes)
    jsonApiClient.insertMiddlewareBefore('response', flightDesignatorRes)
    jsonApiClient.insertMiddlewareBefore('response', priceInCentsRes)
    jsonApiClient.insertMiddlewareBefore('response', serviceClassRes)
    jsonApiClient.insertMiddlewareAfter('response', updatedAtMiddleware)
  }
  if (jsonApiClient.apiUrl !== apiUrl) {
    jsonApiClient.apiUrl = apiUrl
  }
  jsonApiClient = handleAuthentication({ accessToken, jsonApiClient, userUuid })
  return setMissingJsonApiModels(jsonApiClient)
}

export function destroyJsonApiClient (): void {
  if (jsonApiClient) {
    jsonApiClient.headers['Authorization'] = undefined
  }
}

export function getJsonApiClient (): JsonApiInstance {
  if (!jsonApiClient) {
    throw new Error('JSON API client not ready yet')
  }
  // $FlowIgnoreMe this resetBuilder exists
  jsonApiClient.resetBuilder()
  return setMissingJsonApiModels(jsonApiClient)
}

export function setEntityModel (
  entityName: Entity | AllowedIncludeEntity,
  model: Object,
  options?: Object
): typeof jsonApiModels {
  jsonApiModels.push({
    entityName,
    model,
    options
  })

  return jsonApiModels
}
