// @flow
import { handlePostRequest, handleResponse } from './helpers/replaceAttribute'
import type { RequestResponseMiddleware } from '../../../types/JsonApi'

function getPrice (priceInCents, { model } = {}) {
  const price = priceInCents / 100
  switch (model) {
    case 'bid':
      return price
    default:
      return `${price}`
  }
}

export const priceInCentsRes: RequestResponseMiddleware = {
  name: 'priceInCentsRes',
  res: function priceInCentsRes (payload) {
    return handleResponse(payload, {
      from: 'price',
      to: 'price_in_cents',
      value: fromValue => {
        let toValue = fromValue
        if (typeof toValue === 'string') {
          toValue = Number(toValue.replace(',', '.'))
        }
        return toValue * 100
      }
    })
  }
}

export const priceInCentsReq: RequestResponseMiddleware = {
  name: 'priceInCentsReq',
  req: function priceInCentsReq (payload) {
    return handlePostRequest(payload, {
      from: 'price_in_cents',
      to: 'price',
      value: getPrice
    })
  }
}
