import React from 'react'

const IconClose = (props: any) => {
  const { className } = props
  return (
    <svg
      {...props}
      aria-hidden='true'
      className={className}
      fill=''
      focusable='false'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Icon/close</title>
      <desc>
        X closing icon to dismiss a warning, notice, alert... anything
      </desc>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5ZM12.0355 10.692L14.8993 7.82825C15.2703 7.45725 15.8718 7.45725 16.2428 7.82825C16.6138 8.19925 16.6138 8.80075 16.2428 9.17175L13.379 12.0355L16.2428 14.8993C16.6138 15.2703 16.6138 15.8718 16.2428 16.2428C15.8718 16.6138 15.2703 16.6138 14.8993 16.2428L12.0355 13.379L9.17175 16.2428C8.80075 16.6138 8.19925 16.6138 7.82825 16.2428C7.45725 15.8718 7.45725 15.2703 7.82825 14.8993L10.692 12.0355L7.82825 9.17175C7.45725 8.80075 7.45725 8.19925 7.82825 7.82825C8.19925 7.45725 8.80075 7.45725 9.17175 7.82825L12.0355 10.692Z'
        fill='#64666B'
      />
    </svg>
  )
}
export default IconClose
