import React from 'react'

const IconPasswordHidden = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.86492 6.34342L0.806207 2.2847C0.397931 1.87643 0.397931 1.21448 0.806207 0.806207C1.21448 0.397931 1.87643 0.397931 2.2847 0.806207L23.1938 21.7153C23.6021 22.1236 23.6021 22.7855 23.1938 23.1938C22.7855 23.6021 22.1236 23.6021 21.7153 23.1938L17.4551 18.9336C15.8293 19.9585 13.9466 20.522 12 20.5537C8.78779 20.5537 5.97562 19.0022 3.58453 16.4517C2.76305 15.5754 2.04965 14.6398 1.44503 13.7036C1.07972 13.138 0.828977 12.6922 0.695379 12.425C0.557273 12.1488 0.561977 11.8227 0.707993 11.5506C1.76833 9.57457 3.17778 7.8109 4.86492 6.34342ZM8.65686 10.1354C8.1516 10.9896 7.99276 12.0224 8.24544 13.0118C8.58944 14.3588 9.64122 15.4106 10.9882 15.7546C11.9776 16.0072 13.0104 15.8484 13.8646 15.3431L12.8444 14.3229C12.5779 14.4197 12.2929 14.4711 12 14.4711C10.6353 14.4711 9.52893 13.3647 9.52893 12C9.52893 11.7063 9.58062 11.4214 9.67718 11.1557L8.65686 10.1354ZM9.78752 3.69939C9.06217 3.86918 8.80526 4.76953 9.33178 5.29653L12.2383 8.20569C14.1492 8.32388 15.678 9.85377 15.7945 11.7652L19.7293 15.7036C20.1231 16.0978 20.7701 16.0701 21.1289 15.6437C21.9594 14.6567 22.6846 13.5857 23.2927 12.448C23.4381 12.1762 23.4425 11.8507 23.3046 11.575C23.171 11.3078 22.9203 10.862 22.555 10.2964C21.9504 9.36016 21.2369 8.42455 20.4155 7.5483C18.0244 4.99778 15.2122 3.44623 12 3.44623C11.2566 3.44454 10.5134 3.52949 9.78752 3.69939Z'
        fill='#64666B'
      />
    </svg>
  )
}

export default IconPasswordHidden
