import React from 'react'

const IconAngleDown = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Icon/Chevron</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83673 8.31213C5.41656 7.89596 4.73729 7.89596 4.31712 8.31213C3.89429 8.73093 3.89429 9.41193 4.31712 9.83073L11.2402 16.6879C11.6604 17.104 12.3396 17.104 12.7598 16.6879L19.6829 9.83073C20.1057 9.41193 20.1057 8.73093 19.6829 8.31213C19.2627 7.89596 18.5834 7.89596 18.1633 8.31213L12 14.4167L5.83673 8.31213Z'
        fill='#64666B'
      />
    </svg>
  )
}

export default IconAngleDown
