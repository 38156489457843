// @flow
import { omit, set, updateIn } from 'timm'
import type { Entity } from '../../../../types/Entity'
import type {
  ApiResponse,
  Method,
  RequestResponse
} from '../../../../types/JsonApi'

type ReplaceItem = {
  from: string,
  to: string,
  value: (
    from: *,
    options?: {
      method: Method,
      model: Entity
    }
  ) => *
}

function swapValues ({
  params,
  valueToReplace,
  replaceItem,
  method,
  model
}: {
  params: Object,
  valueToReplace: any,
  replaceItem: ReplaceItem,
  method: Method,
  model: Entity
}) {
  return omit(
    set(
      params,
      replaceItem.to,
      replaceItem.value(valueToReplace, {
        method,
        model
      })
    ),
    replaceItem.from
  )
}

export function replaceItemAttribute (
  item: { attributes: Object },
  replaceItem: ReplaceItem
) {
  if (!item.attributes) {
    return item
  }
  let valueToReplace = item.attributes[replaceItem.from]
  if (valueToReplace) {
    valueToReplace = replaceItem.value(valueToReplace)
    return set(
      item,
      'attributes',
      omit(
        set(item.attributes, replaceItem.to, valueToReplace),
        replaceItem.from
      )
    )
  }
  return item
}

export function handleResponse<T: ApiResponse> (
  payload: RequestResponse<T>,
  replaceItem: ReplaceItem
): RequestResponse<T> {
  return updateIn(payload, ['res', 'data', 'data'], data => {
    if (!data) {
      return data
    }
    if (Array.isArray(data)) {
      return data.map(item => replaceItemAttribute(item, replaceItem))
    }
    return replaceItemAttribute(data, replaceItem)
  })
}

export function handlePostRequest<T: ApiResponse> (
  payload: RequestResponse<T>,
  replaceItem: ReplaceItem
): RequestResponse<T> {
  const {
    method,
    model,
    data: { data: { attributes } = {} } = {},
    params: { filter } = {}
  } = payload.req
  if (method === 'POST') {
    const attributeValue = attributes[replaceItem.from]
    if (attributeValue) {
      return updateIn(
        payload,
        ['req', 'data', 'data', 'attributes'],
        attributes =>
          swapValues({
            ...payload.req,
            params: attributes,
            replaceItem,
            valueToReplace: attributeValue
          })
      )
    }
  }
  return payload
}

export function handleGetRequest<T: ApiResponse> (
  payload: RequestResponse<T>,
  replaceItem: ReplaceItem
): RequestResponse<T> {
  const { method, model, params: { filter } = {} } = payload.req
  if (method === 'GET' && filter) {
    const filterValue = filter[replaceItem.from]
    if (typeof filterValue !== 'undefined') {
      return updateIn(payload, ['req', 'params', 'filter'], filters =>
        swapValues({
          ...payload.req,
          params: filters,
          replaceItem,
          valueToReplace: filterValue
        })
      )
    }
  }
  return payload
}
