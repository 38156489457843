import React from 'react'

const IconPasswordVisible = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.601174 11.6964C0.466275 11.9662 0.466275 12.2838 0.601174 12.5536C0.735885 12.823 0.988719 13.2725 1.35707 13.8428C1.96673 14.7868 2.68608 15.7302 3.5144 16.6138C5.92542 19.1855 8.76102 20.75 12 20.75C15.239 20.75 18.0746 19.1855 20.4856 16.6138C21.3139 15.7302 22.0333 14.7868 22.6429 13.8428C23.0113 13.2725 23.2641 12.823 23.3988 12.5536C23.5337 12.2838 23.5337 11.9662 23.3988 11.6964C23.2641 11.427 23.0113 10.9775 22.6429 10.4072C22.0333 9.46318 21.3139 8.51977 20.4856 7.63622C18.0746 5.06447 15.239 3.5 12 3.5C8.76102 3.5 5.92542 5.06447 3.5144 7.63622C2.68608 8.51977 1.96673 9.46318 1.35707 10.4072C0.988719 10.9775 0.735885 11.427 0.601174 11.6964ZM12 15.9583C14.1171 15.9583 15.8333 14.2421 15.8333 12.125C15.8333 10.0079 14.1171 8.29167 12 8.29167C9.88291 8.29167 8.16667 10.0079 8.16667 12.125C8.16667 14.2421 9.88291 15.9583 12 15.9583ZM12 14.5208C10.6768 14.5208 9.60417 13.4482 9.60417 12.125C9.60417 10.8018 10.6768 9.72917 12 9.72917C13.3232 9.72917 14.3958 10.8018 14.3958 12.125C14.3958 13.4482 13.3232 14.5208 12 14.5208Z'
        fill='#64666B'
      />
    </svg>
  )
}

export default IconPasswordVisible
