import { AllowedFilters } from '../types/List'

export type AllowedParam =
  | AllowedFilters
  | 'parent_id'
  | 'doc_id'
  | 'id'
  | 'offer'
  | 'page'
  | 'planned'
  | 'redirect_to'
  | 'token'

export default function getParamFromUrl (
  param: AllowedParam,
  url: string = (global as any).location.href
): string {
  return new URL(url).searchParams.get(param) || ''
}

export function getUrlParam (param: string): string | null {
  return new URL((global as any).location.href).searchParams.get(param)
}
