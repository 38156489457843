import React from 'react'

import { FormattedMessage } from 'react-intl'

import { MessageDescriptorWithValues } from '../flashMessage'

interface RawFormattedMessageProps extends MessageDescriptorWithValues {
  className?: string
  tagName?: keyof JSX.IntrinsicElements
}

export default function RawFormattedMessage (props: RawFormattedMessageProps) {
  const Tag = props.tagName || 'span'
  if (!props.id) {
    return null
  }
  return (
    <FormattedMessage {...props}>
      {txt => <Tag className={props.className}>{txt}</Tag>}
    </FormattedMessage>
  )
}
