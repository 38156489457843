import { ResponseMiddleware } from '../../../types/JsonApi'

export const statusCodeRes: ResponseMiddleware = {
  name: 'statusCode',
  res: function statusCodeResponse (payload: any) {
    if (Array.isArray(payload.data)) {
      // status and method are only used for items, not for lists
      return payload
    }
    return {
      ...payload,
      res: {
        ...payload.res,
        data: {
          ...payload.res.data,
          meta: {
            ...payload.res.data.meta,
            status: payload.res.status,
            method: payload.res.method
          }
        }
      }
    }
  }
}
