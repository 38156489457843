export enum OnboardingEntityProgress {
  NotStarted = 'not_started',
  Partial = 'partial',
  Complete = 'complete'
}

export enum OnboardingStatus {
  InProgress = 'in_progress',
  Submitted = 'submitted',
  ReOpened = 'reopened',
  Approved = 'approved',
  WebinarFailed = 'webinar_failed',
  WebinarScheduled = 'webinar_scheduled',
  Certified = 'certified',
  ContractSigned = 'contract_signed',
  Completed = 'completed',
  Rejected = 'rejected'
}

export enum ApplicantType {
  IndividualChauffeur = 'individual_chauffeur',
  CompanyWithRepresentativeAsDriver = 'company_with_representative_as_driver',
  CompanyWithSeparateDriver = 'company_with_separate_driver'
}

export type Lsp = {
  name: string
  address: string
  zip: string
  city: string
  countryCode: string
}

export type Vehicle = {
  uuid: string
  plate: string
  model: string
  year: number
}

export type OnboardingData = {
  applicantType: ApplicantType
  chauffeurDocumentsTotal: number
  chauffeurDocumentsUploaded: number
  chauffeurDocumentsRejected: number
  lspDocumentsTotal: number
  lspDocumentsUploaded: number
  lspDocumentsRejected: number
  lspProgress: OnboardingEntityProgress
  lsp: Lsp
  status: OnboardingStatus
  overallProgressPercentage: number
  vehicleProgress: OnboardingEntityProgress
  vehicle?: Vehicle
  vehicleDocumentsTotal: number
  vehicleDocumentsUploaded: number
  vehicleDocumentsRejected: number
  contractSignedAt: string | null
  companyRepresentative: Chauffeur
  chauffeurs: Chauffeur[]
}

export type Chauffeur = {
  uuid: string
  title: string
  firstName: string
  lastName: string
  mobile: string
  email: string
  trainingCoursesCompleted: number
  trainingCoursesTotal: number
  webinarScheduledAt: string | null
  webinarTimezone: string | null
  webinarBookedAt: string | null
  webinarCancelledAt: string | null
  webinarBookingRef: string | null
  progress: OnboardingEntityProgress
}

export function determineFirstChauffeur (
  data: OnboardingData
): Chauffeur | null {
  if (data.applicantType === ApplicantType.CompanyWithSeparateDriver) {
    if (data.chauffeurs.length === 1) {
      return data.chauffeurs[0]
    }
  } else {
    return data.companyRepresentative
  }

  return null
}
