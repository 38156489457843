import Router from 'next/router'
import * as React from 'react'
import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import hasUserPermissions, { canDo } from './hasUserPermissions'
import getParamFromURL from '../getParamFromUrl'
import Loading from '../components/Loading'
import { actionCreators } from '../me'
import * as roles from './roles'
// @ts-ignore
import * as routes from '../routes'
// @ts-ignore
import { State } from '../store/reducer'
import { AsyncAction } from '../../types/Action'
// @ts-ignore
import { Page } from '../../types/Routes'
import {
  CanDos,
  GroupedRole,
  PagePermissionsArray,
  PagePermissionsWithActions,
  Role
} from '../../types/Role'
import styles from './withAuthentication.module.css'
import { OnboardingStatus } from '../onboarding/overview/onboardingData'
import { trackError } from '../tracking/error'

export const homePageRoutes = {
  [roles.ADMIN]: routes.chauffeursList(),
  [roles.DISPATCHER]: routes.planned(),
  [roles.DRIVER]: routes.planned(),
  [roles.PROVIDER]: routes.offers(),
  [roles.REVIEWER]: routes.finished(),
  [roles.LOGGED_IN]: routes.me()
}

function getHomeRouteFor (
  userRoles: Role[] = [],
  isLspBeingOnboarded: boolean
) {
  const orderedRoles: Role[] = [
    roles.PROVIDER,
    roles.DRIVER,
    roles.DISPATCHER,
    roles.REVIEWER,
    roles.ADMIN
  ]

  if (isLspBeingOnboarded) {
    return routes.onboardingDashboard()
  }
  const predominantRole: Role | GroupedRole =
    orderedRoles.find(role => userRoles.includes(role)) || roles.LOGGED_IN
  return homePageRoutes[predominantRole]
}

type WithAuthenticationProps = {
  isReady: boolean
  hasFetchedRoles: boolean
  isLoggedIn: boolean
  loadMe: () => AsyncAction<any>
  userRoles: Role[]
  isLspBeingOnboarded: boolean
  onboardingStatus: OnboardingStatus
}
type WithAuthenticationState = {
  canDo: CanDos
  hasCheckedUserPermissions: boolean
  pagePermissions: PagePermissionsArray
  pagePermissionsWithActions: PagePermissionsWithActions
  redirectTo?: string
}

export const selectors = {
  isLoggedIn ({ login: { isLoggedIn = false } = {} }: State = {}): boolean {
    return isLoggedIn
  },
  isReady ({
    api: { didCheckForCookie = false } = {},
    me: { isFetching = false } = {}
  }: State = {}): boolean {
    return didCheckForCookie && !isFetching
  },
  hasFetchedRoles ({ me: { hasFetched = false } = {} }: State = {}): boolean {
    return hasFetched
  },
  // tslint:disable-next-line
  userRoles ({ me: { roles = [] } = {} }: State = {}): Role[] {
    return roles
  },
  isLspBeingOnboarded (state: State = {}): boolean {
    const onboardingStatus = state.me?.lsp?.onboardingStatus
    if (!onboardingStatus) {
      return false
    }
    return onboardingStatus !== OnboardingStatus.Completed
  },
  onboardingStatus (state: State = {}): OnboardingStatus {
    return state.me?.lsp?.onboardingStatus
  }
}

export function createWithAuthentication (
  permissions: PagePermissionsWithActions,
  // @ts-ignore
  ChildComponent: NextComponentType
) {
  const childComponentName =
    ChildComponent.displayName || String(ChildComponent.name || 'Component')
  return class WithAuthentication extends React.Component<
    WithAuthenticationProps,
    WithAuthenticationState
  > {
    static async getInitialProps (args: any) {
      if (typeof ChildComponent.getInitialProps === 'function') {
        return ChildComponent.getInitialProps(args)
      }
      return {}
    }

    get displayName () {
      return `WithAuthentication(${childComponentName})`
    }

    // tslint:disable-next-line
    constructor (...props: any) {
      // @ts-ignore
      super(...props)
      this.state = {
        canDo: {
          access: false
        },
        hasCheckedUserPermissions: false,
        pagePermissions: permissions.access!,
        pagePermissionsWithActions: permissions,
        redirectTo: getParamFromURL('redirect_to')
      }
    }

    redirectToLogin () {
      const { href, as } = routes.login({})
      Router.push(href, as)
    }

    redirectOnLoginSuccess (userRoles: Role[]) {
      const { href, as } = getHomeRouteFor(
        userRoles,
        this.props.isLspBeingOnboarded
      )
      const { redirectTo } = this.state
      if (redirectTo) {
        if (redirectTo !== '/') {
          trackError(
            'Got a redirect_to parameter other than "/" when logging in. This is no real error, just a monitoring for us to find out that this feature is actually still used for some deep-linking maybe? The redirect_to param was: ' +
              redirectTo
          )
        }

        const sanitizedRedirectTo = redirectTo
          .split('/')
          .filter(value => !!value)
          .join('/')
        Router.push(`/${sanitizedRedirectTo}`)
      } else {
        Router.push(href, as)
      }
    }

    redirectToNotAuthorized () {
      const { href, as } = routes.notAuthorized()
      Router.push(href, as)
    }

    redirectToOnboardingDashboard () {
      const { href, as } = routes.onboardingDashboard()
      Router.push(href, as)
    }

    redirectUser () {
      const {
        isLoggedIn,
        userRoles,
        isReady,
        isLspBeingOnboarded,
        onboardingStatus
      } = this.props
      const { pagePermissions } = this.state

      // false if:
      // 1) Login is required and there are no roles
      // 2) Logout is required and there is any role
      // 3) LSP is not onboarding and should be redirected to the user's home page
      // 4) A specific role is required and the user doesn't have it
      if (
        isReady &&
        !hasUserPermissions(userRoles, pagePermissions, isLspBeingOnboarded)
      ) {
        if (!isLoggedIn || pagePermissions.includes(roles.LOGGED_IN)) {
          // Handles (1): redirects to login page
          // Also handles (3), when a user is logged out
          this.redirectToLogin()
        } else if (pagePermissions.includes(roles.LOGGED_OUT)) {
          // Handles (2): redirects to the home page
          this.redirectOnLoginSuccess(userRoles)
        } else if (
          pagePermissions.includes(roles.ONBOARDING) &&
          !isLspBeingOnboarded
        ) {
          // Handles (3): redirects when the LSP is not being onboarded
          const { href, as } = getHomeRouteFor(userRoles, isLspBeingOnboarded)
          Router.push(href, as)
        } else {
          // Handles (4): redirects to not authorized
          this.redirectToNotAuthorized()
        }
      } else {
        // Redirects to the onboarding dashboard if the user is being onboarded and the onboarding was rejected
        if (
          pagePermissions.includes(roles.ONBOARDING) &&
          isLspBeingOnboarded &&
          onboardingStatus === OnboardingStatus.Rejected
        ) {
          this.redirectToOnboardingDashboard()
        }
      }
    }

    fetchRoles () {
      const { isLoggedIn, isReady, hasFetchedRoles, loadMe } = this.props
      if (isReady && isLoggedIn && !hasFetchedRoles) {
        loadMe()
      }
    }

    doesPageRequireRolesToCheck (pagePermissions: PagePermissionsArray) {
      return (
        pagePermissions.length > 0 &&
        !pagePermissions.includes(roles.LOGGED_OUT)
      )
    }

    checkUserPermissions () {
      const { isLoggedIn, isReady, hasFetchedRoles } = this.props
      if (!isReady) {
        return
      }
      if (isLoggedIn && !hasFetchedRoles) {
        return this.fetchRoles()
      }
      this.redirectUser()
      if (!this.state.hasCheckedUserPermissions) {
        this.setState({
          hasCheckedUserPermissions: true,
          canDo: canDo(
            this.props.userRoles,
            this.state.pagePermissionsWithActions,
            this.props.isLspBeingOnboarded
          )
        })
      }
    }

    componentDidMount () {
      this.checkUserPermissions()
    }

    componentDidUpdate (prevProps: WithAuthenticationProps) {
      if (
        this.props.isReady !== prevProps.isReady ||
        this.props.hasFetchedRoles !== prevProps.hasFetchedRoles ||
        this.props.isLoggedIn !== prevProps.isLoggedIn
      ) {
        this.checkUserPermissions()
      }
    }

    render () {
      if (
        this.doesPageRequireRolesToCheck(this.state.pagePermissions) &&
        !this.props.hasFetchedRoles
      ) {
        return <Loading />
      }
      if (!this.state.hasCheckedUserPermissions) {
        return <div className={styles.loadingMessage}>Authenticating...</div>
      }
      if (!this.state.canDo.access) {
        return null
      }
      return <ChildComponent {...this.props} canDo={this.state.canDo} />
    }
  }
}

export type ComponentProps = {
  canDo: CanDos
}

export default (pagePermissions: PagePermissionsWithActions) => (
  ChildComponent: ComponentType<ComponentProps>
) => {
  function mapStateToProps (state: State) {
    return {
      hasFetchedRoles: selectors.hasFetchedRoles(state),
      isLoggedIn: selectors.isLoggedIn(state),
      isReady: selectors.isReady(state),
      userRoles: selectors.userRoles(state),
      isLspBeingOnboarded: selectors.isLspBeingOnboarded(state),
      onboardingStatus: selectors.onboardingStatus(state)
    }
  }

  function mapDispatchToProps (dispatch: Dispatch) {
    return bindActionCreators(actionCreators, dispatch)
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(createWithAuthentication(pagePermissions, ChildComponent))
}
