import router from 'next/router'

import { Link as LinkType } from '../types/Link'

const linkPrefix = process.env.LINK_PREFIX || ''

export function routerPush (path: string, query: any = {}) {
  const r = withPrefix(path, query)
  router.push(r.href, r.as)
}

export function withPrefix (path: string, query: any = {}): LinkType {
  return {
    href: {
      pathname: `/${path}`,
      query,
      // @ts-ignore
      origin: global && global.location && global.location.origin
    },
    as: {
      pathname: `${linkPrefix}/${path ? path + '/' : path}`,
      query
    }
  }
}
