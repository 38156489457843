// @flow
import { setIn } from 'timm'

import { errorCodes } from '../errors'
import type { ErrorCode } from '../../../types/JsonApi'

function getJsonApiCode (code): ErrorCode {
  switch (code) {
    case 'not_found':
      return errorCodes.NOT_FOUND
    default:
      return errorCodes.ATHENA_ERROR
  }
}

const athenaError = {
  name: 'athenaError',
  error: (error: {
    response: Object,
    config: Object,
    status: number | string
  }) => {
    const { response: { data, config, status } = {} } = error
    if (data.error) {
      return setIn(error, ['response', 'data'], {
        errors: [
          {
            code: getJsonApiCode(data.error),
            detail: `Requested URL was ${config.url} with method ${
              config.method
            }; model: ${config.model}; params: ${JSON.stringify(
              config.params
            )}.`,
            status: `${status}`,
            title: `Athena failed with the status ${status}`
          }
        ]
      })
    }
    return error
  }
}

export default athenaError
