import { setIn } from 'timm'
import * as roles from './roles'
import {
  CanDos,
  Role,
  PagePermissionsArray,
  PagePermissionsWithActions
} from '../../types/Role'
import {NOT_ONBOARDING, ONBOARDING} from "./roles";

export default function hasUserPermissions (
  userRoles: Role[] = [],
  pagePermissions: PagePermissionsArray = [],
  isLspBeingOnboarded: boolean
): boolean {
  let hasPermissions = true
  const isUserLoggedIn = userRoles.length > 0
  // go here only if we have specific permission requirements
  if (pagePermissions.length > 0) {
    if (pagePermissions.includes(ONBOARDING) && !isLspBeingOnboarded) {
      return false
    }
    if (pagePermissions.includes(NOT_ONBOARDING) && isLspBeingOnboarded) {
      return false
    }

    hasPermissions = false
    for (
      let i = 0, pagePermission;
      // tslint:disable-next-line
      (pagePermission = pagePermissions[i]);
      i++
    ) {
      // will grant permission if user has any role (she's logged in) and LOGGED_IN is required
      if (pagePermission === roles.LOGGED_IN) {
        hasPermissions = isUserLoggedIn
        break
      }
      // will grant permission if user has no roles (she's logged out) if LOGGED_OUT is required
      if (pagePermission === roles.LOGGED_OUT) {
        hasPermissions = !isUserLoggedIn
        break
      }
      // will grant permission if user has any of the page permissions
      // @ts-ignore
      if (userRoles.includes(pagePermission)) {
        hasPermissions = true
        break
      }
    }
  }
  return hasPermissions
}

export function canDo (
  userRoles: Role[] = [],
  pagePermissions: PagePermissionsWithActions,
  isLspBeingOnboarded: boolean
): CanDos {
  return (
    Object.entries(pagePermissions)
      // $FlowFixMe usual Object.entries stuff
      .reduce(
        (canDos, [action, requiredRoles]: [string, PagePermissionsArray]) =>
          setIn(canDos, [action], hasUserPermissions(userRoles, requiredRoles, isLspBeingOnboarded)),
        {}
      )
  )
}
