// @flow
import { set, setIn } from 'timm'
import type { ResponseMiddleware } from '../../../types/JsonApi'

const updatedAt: ResponseMiddleware = {
  name: 'updatedAt',
  res: function updatedAtRes (payload) {
    if (Array.isArray(payload.data)) {
      return set(
        payload,
        'data',
        payload.data.map(item => set(item, 'updatedAt', global.Date.now()))
      )
    }
    return setIn(payload, ['data', 'updatedAt'], global.Date.now())
  }
}

export default updatedAt
