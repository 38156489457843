// @flow
import type { Router as RouterType } from 'next/router'

import { merge } from 'timm'
import {
  ADMIN,
  DISPATCHER,
  DRIVER,
  LOGGED_IN,
  LOGGED_OUT,
  NOT_ONBOARDING,
  ONBOARDING,
  PROVIDER,
  REVIEWER
  // $FlowIgnore TS import
} from './withAuthentication/roles'
// $FlowIgnore TS import
import { withPrefix } from './typedRoutes'
import type { Link as LinkType, ListLinkParams } from '../types/Link'
import type {
  CanDoAction,
  PagePermissionsWithActions,
  Role
} from '../types/Role'
import type { Page } from '../types/Routes'

export function doesRouteMatchWith (
  router: RouterType,
  matchingRoutes: LinkType | Array<LinkType>
) {
  return Boolean(
    [].concat(matchingRoutes).some(matcher => {
      return matcher.href.pathname === router.pathname
    })
  )
}

function canAccess (
  userRoles: ?Array<Role>,
  pagePermissions: PagePermissionsWithActions,
  action: CanDoAction = 'access'
): LinkType => LinkType {
  let canAccess = false
  if (userRoles) {
    canAccess = Boolean(
      pagePermissions[action].find(pagePermission =>
        (userRoles || []).includes(pagePermission)
      )
    )
  }
  return link => merge(link, canAccess ? { canAccess } : {})
}

function canAccessWithPrefix ({
  userRoles,
  page,
  qs
}: {
  userRoles?: Array<Role>,
  page: Page,
  qs?: Object
}): LinkType {
  return canAccess(userRoles, pagePermissions[page])(withPrefix(page, qs))
}

const pagePermissions: {
  [id: Page]: PagePermissionsWithActions
} = {}

export const LOGIN: Page = 'login'
pagePermissions[LOGIN] = {
  access: [LOGGED_OUT]
}

export function login ({
  pathname = '/',
  search = ''
}: {
  pathname?: string,
  search?: string
}): LinkType {
  return withPrefix(LOGIN, {
    redirect_to: `${pathname}${search}`
  })
}

export const LOGIN_AS: Page = 'login-as'
pagePermissions[LOGIN_AS] = {
  access: [LOGGED_OUT]
}

export function loginAs ({
  pathname = '/',
  search = ''
}: {
  pathname?: string,
  search?: string
}): LinkType {
  return withPrefix(LOGIN_AS, {
    redirect_to: `${pathname}${search}`
  })
}

export const HOME: Page = ''
pagePermissions[HOME] = {
  access: [LOGGED_OUT]
}

export function home (): LinkType {
  return withPrefix(HOME)
}

export const NOT_AUTHORIZED: Page = '403'
pagePermissions[NOT_AUTHORIZED] = {
  access: [LOGGED_IN]
}

export function notAuthorized (): LinkType {
  return withPrefix(NOT_AUTHORIZED)
}

export const ONBOARDING_DASHBOARD: Page = 'onboarding'
pagePermissions[ONBOARDING_DASHBOARD] = {
  access: [ADMIN, DRIVER, ONBOARDING]
}

export function onboardingDashboard (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: ONBOARDING_DASHBOARD })
}

export const CHAUFFEURS_LIST: Page = 'chauffeurs'
pagePermissions[CHAUFFEURS_LIST] = {
  access: [ADMIN, DISPATCHER, REVIEWER, NOT_ONBOARDING],
  add: [ADMIN]
}

export function chauffeursList (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: CHAUFFEURS_LIST })
}

export const CHAUFFEUR_ADD: Page = 'chauffeur-add'
pagePermissions[CHAUFFEUR_ADD] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function chauffeurAdd (): LinkType {
  return withPrefix(CHAUFFEUR_ADD)
}

export const CHAUFFEUR_DETAIL: Page = 'chauffeur-detail'
pagePermissions[CHAUFFEUR_DETAIL] = {
  access: [ADMIN, DISPATCHER, REVIEWER, NOT_ONBOARDING],
  deleteResource: [ADMIN],
  edit: [ADMIN]
}

export function chauffeurDetail (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: CHAUFFEUR_DETAIL })
}

export const CHAUFFEUR_DOCS: Page = 'chauffeur-docs'
pagePermissions[CHAUFFEUR_DOCS] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function chauffeurDocs (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: CHAUFFEUR_DOCS })
}

export const CHAUFFEUR_DOCUMENT_UPLOAD: Page = 'chauffeur-doc-upload'
pagePermissions[CHAUFFEUR_DOCUMENT_UPLOAD] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function chauffeurDocumentUpload (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: CHAUFFEUR_DOCUMENT_UPLOAD })
}

export const ONBOARDING_CHAUFFEUR_DOCS: Page = 'onboarding/chauffeur-docs'
pagePermissions[ONBOARDING_CHAUFFEUR_DOCS] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingChauffeurDocuments (): LinkType {
  return withPrefix(ONBOARDING_CHAUFFEUR_DOCS)
}

export const ONBOARDING_CHAUFFEUR_DOC_UPLOAD: Page =
  'onboarding/chauffeur-doc-upload'
pagePermissions[ONBOARDING_CHAUFFEUR_DOC_UPLOAD] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingChauffeurDocUpload (
  listParams: ListLinkParams
): LinkType {
  return canAccessWithPrefix({
    ...listParams,
    page: ONBOARDING_CHAUFFEUR_DOC_UPLOAD
  })
}

export const CHAUFFEUR_EDIT: Page = 'chauffeur-edit'
pagePermissions[CHAUFFEUR_EDIT] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function chauffeurEdit ({ qs: { id = '' } = {} }: Object): LinkType {
  return withPrefix(CHAUFFEUR_EDIT, { id })
}

export const ONBOARDING_CHAUFFEUR: Page = 'onboarding/chauffeur'
pagePermissions[ONBOARDING_CHAUFFEUR] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingChauffeur (): LinkType {
  return withPrefix(ONBOARDING_CHAUFFEUR)
}

export const ONBOARDING_LSP_EDIT: Page = 'onboarding/lsp-edit'
pagePermissions[ONBOARDING_LSP_EDIT] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingLspEdit (): LinkType {
  return withPrefix(ONBOARDING_LSP_EDIT)
}

export const LSP_DOCS: Page = 'lsp-docs'
pagePermissions[LSP_DOCS] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function lspDocs (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: LSP_DOCS })
}

export const LSP_DOCUMENT_UPLOAD: Page = 'lsp-doc-upload'
pagePermissions[LSP_DOCUMENT_UPLOAD] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function lspDocumentUpload (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: LSP_DOCUMENT_UPLOAD })
}

export const VEHICLES_LIST: Page = 'vehicles'
pagePermissions[VEHICLES_LIST] = {
  access: [ADMIN, DISPATCHER, NOT_ONBOARDING],
  add: [ADMIN]
}

export function vehiclesList (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: VEHICLES_LIST })
}

export const VEHICLE_DETAIL: Page = 'vehicle-detail'
pagePermissions[VEHICLE_DETAIL] = {
  access: [ADMIN, DISPATCHER, NOT_ONBOARDING]
}

export function vehicleDetail (detailParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...detailParams, page: VEHICLE_DETAIL })
}

export const VEHICLE_ADD: Page = 'vehicle-add'
pagePermissions[VEHICLE_ADD] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function vehicleAdd (): LinkType {
  return withPrefix(VEHICLE_ADD)
}

export const ONBOARDING_FIRST_VEHICLE: Page = 'onboarding/vehicle'
pagePermissions[ONBOARDING_FIRST_VEHICLE] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingFirstVehicle (): LinkType {
  return withPrefix(ONBOARDING_FIRST_VEHICLE)
}

export const VEHICLE_DOCS: Page = 'vehicle-docs'
pagePermissions[VEHICLE_DOCS] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function vehicleDocs (detailParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...detailParams, page: VEHICLE_DOCS })
}

export const ONBOARDING_VEHICLE_DOCS: Page = 'onboarding/vehicle-docs'
pagePermissions[ONBOARDING_VEHICLE_DOCS] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingVehicleDocs (): LinkType {
  return canAccessWithPrefix({ page: ONBOARDING_VEHICLE_DOCS })
}

export const ONBOARDING_VEHICLE_DOC_UPLOAD: Page =
  'onboarding/vehicle-doc-upload'
pagePermissions[ONBOARDING_VEHICLE_DOC_UPLOAD] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingVehicleDocUpload (
  listParams: ListLinkParams
): LinkType {
  return canAccessWithPrefix({
    ...listParams,
    page: ONBOARDING_VEHICLE_DOC_UPLOAD
  })
}

export const ONBOARDING_LSP_DOCS: Page = 'onboarding/lsp-docs'
pagePermissions[ONBOARDING_LSP_DOCS] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingLspDocs (): LinkType {
  return canAccessWithPrefix({ page: ONBOARDING_LSP_DOCS })
}

export const ONBOARDING_LSP_DOC_UPLOAD: Page = 'onboarding/lsp-doc-upload'
pagePermissions[ONBOARDING_LSP_DOC_UPLOAD] = {
  access: [ADMIN, ONBOARDING]
}

export function onboardingLspDocUpload (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({
    ...listParams,
    page: ONBOARDING_LSP_DOC_UPLOAD
  })
}

export const ONBOARDING_TRAINING: Page = 'onboarding/training'
pagePermissions[ONBOARDING_TRAINING] = {
  access: [ADMIN, DRIVER, ONBOARDING]
}

export function onboardingTraining (): LinkType {
  return canAccessWithPrefix({ page: ONBOARDING_TRAINING })
}

export const VEHICLE_DOCUMENT_UPLOAD: Page = 'vehicle-doc-upload'
pagePermissions[VEHICLE_DOCUMENT_UPLOAD] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function vehicleDocumentUpload (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: VEHICLE_DOCUMENT_UPLOAD })
}

export const RATINGS_LIST: Page = 'ratings'
pagePermissions[RATINGS_LIST] = {
  access: [ADMIN, NOT_ONBOARDING]
}

export function ratingsList (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: RATINGS_LIST })
}

export const OFFER_LIST: Page = 'offers'
pagePermissions[OFFER_LIST] = {
  access: [PROVIDER, NOT_ONBOARDING]
}

export function offers (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: OFFER_LIST })
}

export const OFFER_DETAIL: Page = 'offer-detail'
pagePermissions[OFFER_DETAIL] = {
  access: [PROVIDER, NOT_ONBOARDING],
  acceptOffer: [PROVIDER]
}

export function offerDetail ({ qs: { id = '' } = {} }: Object): LinkType {
  return withPrefix(OFFER_DETAIL, { id })
}

export const PLANNED_LIST: Page = 'planned'
pagePermissions[PLANNED_LIST] = {
  access: [DISPATCHER, DRIVER, NOT_ONBOARDING],
  assignChauffeur: [DISPATCHER],
  assignVehicle: [DISPATCHER, DRIVER]
}

export function planned (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: PLANNED_LIST })
}

export const PLANNED_DETAIL: Page = 'planned-detail'
pagePermissions[PLANNED_DETAIL] = {
  access: [DISPATCHER, DRIVER, NOT_ONBOARDING],
  assignChauffeur: [DISPATCHER],
  seePrice: [ADMIN],
  assignVehicle: [DISPATCHER, DRIVER]
}

export function plannedDetail ({ qs: { id = '' } = {} }: Object): LinkType {
  return withPrefix(PLANNED_DETAIL, { id })
}

export const FINISHED_LIST: Page = 'finished'
pagePermissions[FINISHED_LIST] = {
  access: [DISPATCHER, DRIVER, REVIEWER, NOT_ONBOARDING],
  review: [REVIEWER]
}

export function finished (listParams: ListLinkParams): LinkType {
  return canAccessWithPrefix({ ...listParams, page: FINISHED_LIST })
}

export const FINISHED_DETAIL: Page = 'finished-detail'
pagePermissions[FINISHED_DETAIL] = {
  access: [DISPATCHER, DRIVER, REVIEWER, NOT_ONBOARDING],
  review: [REVIEWER],
  seePrice: [ADMIN]
}

export function finishedDetail ({ qs: { id = '' } = {} }: Object): LinkType {
  return withPrefix(FINISHED_DETAIL, { id })
}

export const PENDING_OFFERS: Page = 'pending-offers'
pagePermissions[PENDING_OFFERS] = {
  access: [ADMIN, DISPATCHER, NOT_ONBOARDING]
}

export function pendingOffers ({ qs: { id = '' } = {} }: Object): LinkType {
  return withPrefix(PENDING_OFFERS, { id })
}

export const HELP: Page = 'help'
pagePermissions[HELP] = {
  access: [LOGGED_IN]
}

export function help (): LinkType {
  // $FlowIgnore external link
  return {
    href: 'https://partner-help.blacklane.com',
    as: 'https://partner-help.blacklane.com'
  }
}

export const PROFILE: Page = 'me'
pagePermissions[PROFILE] = {
  access: [LOGGED_IN]
}

export function me (): LinkType {
  return withPrefix(PROFILE)
}

export const RESET_PASSWORD: Page = 'reset-password'
pagePermissions[RESET_PASSWORD] = {
  access: [LOGGED_OUT]
}

export function resetPassword (): LinkType {
  return withPrefix(RESET_PASSWORD)
}

export const UPDATE_PASSWORD: Page = 'update-password'
pagePermissions[UPDATE_PASSWORD] = {
  access: [LOGGED_OUT]
}

export function updatePassword (): LinkType {
  return withPrefix(UPDATE_PASSWORD)
}

export const UPDATE_CHAUFFEUR_SIGNUP_PASSWORD: Page =
  'update-chauffeur-signup-password'
pagePermissions[UPDATE_CHAUFFEUR_SIGNUP_PASSWORD] = {
  access: [LOGGED_OUT]
}

export function updateChauffeurSignupPassword (): LinkType {
  return withPrefix(UPDATE_CHAUFFEUR_SIGNUP_PASSWORD)
}

export const PUBLIC: Page = '__publicPage__'
pagePermissions[PUBLIC] = {
  access: []
}

export const permissions = pagePermissions
