import classnames from 'classnames'
import React, { forwardRef, useState } from 'react'

import styles from './Input.module.css'
import IconPasswordHidden from './Icon/IconPasswordHidden'
import IconPasswordVisible from './Icon/IconPasswordVisible'
import IconAngleDown from './Icon/IconAngleDown'
import IconSearch from './Icon/IconSearch'
import IconClose from './Icon/IconClose'
import PhoneNumberInput from 'react-phone-number-input'

interface BasicInputProps {
  description?: string | React.ReactNode
  error?: string
  icon?: React.ReactNode
  id?: string
  label?: string | React.ReactNode
  containerClassName?: string
}

type InputProps = BasicInputProps & JSX.IntrinsicElements['input']
type TextAreaProps = BasicInputProps & JSX.IntrinsicElements['textarea']
type SelectProps = BasicInputProps & JSX.IntrinsicElements['select']

const input = styles.input
const nonTrailingIconInput = styles.nonTrailingIconInput
const searchInput = styles.searchInput

function InputContainer ({
  children,
  containerClassName = '',
  description,
  disabled,
  error,
  icon,
  label,
  value,
  ...inputProps
}: InputProps | TextAreaProps | SelectProps) {
  return (
    <div className={classnames(styles.container, containerClassName)}>
      <div className={styles.labelAndInput}>
        {children}
        <label
          htmlFor={inputProps.id}
          className={classnames(
            styles.inputLabel,
            disabled && styles.disabledInputLabel,
            error && styles.errorLabel
          )}
        >
          {label}
        </label>
        {icon && (
          <span
            role='presentation'
            className={classnames(
              styles.iconSpan,
              styles.iconRight,
              disabled && styles.disabledIconSpan
            )}
          >
            {icon}
          </span>
        )}
      </div>
      <ValidationError message={error} />
      {description && (
        <small className={styles.inputDescription}>{description}</small>
      )}
    </div>
  )
}

const Input = forwardRef(
  (props: InputProps, ref?: React.Ref<HTMLInputElement>) => {
    const { disabled, error, icon, ...inputProps } = props

    if (inputProps) {
      // avoid illegal attributes on input
      delete inputProps.label
      delete inputProps.containerClassName
    }
    const inputID = props.id || (Math.random() + 1).toString(36).substring(7)

    return (
      <InputContainer {...props} id={inputID} icon={icon}>
        <input
          {...inputProps}
          className={classnames(
            input,
            nonTrailingIconInput,
            disabled && styles.disabledInput,
            error && styles.errorInput
          )}
          disabled={disabled}
          id={inputID}
          ref={ref}
        />
      </InputContainer>
    )
  }
)

interface PasswordInputState {
  type: 'password' | 'input'
}

type PasswordInputProps = InputProps & {
  innerRef?: React.Ref<HTMLInputElement>
}

export const PasswordInputRef = forwardRef(
  (props: InputProps, ref?: React.Ref<HTMLInputElement>) => (
    // @ts-ignore
    <PasswordInput {...props} innerRef={ref} />
  )
)

export class PasswordInput extends React.Component<
  PasswordInputProps,
  PasswordInputState
> {
  constructor (props: PasswordInputProps) {
    super(props)
    this.state = {
      type: 'password'
    }
  }

  unMaskPassword () {
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  render () {
    const icon = (
      <button
        type='button'
        aria-label='Show Password'
        className={styles.passwordIcon}
        onClick={() => this.unMaskPassword()}
      >
        {this.state.type === 'password' ? (
          <IconPasswordHidden />
        ) : (
          <IconPasswordVisible />
        )}
      </button>
    )

    return (
      <Input
        aria-required='true'
        autoComplete='on'
        icon={icon}
        required={true}
        type={this.state.type}
        ref={this.props.innerRef}
        {...(this.props as any)}
      />
    )
  }
}

export function TextArea (props: TextAreaProps) {
  const { disabled, icon, ...inputProps } = props
  if (inputProps) {
    delete inputProps.label // avoid invalid attribute on textarea
  }
  return (
    <InputContainer {...props} icon={icon}>
      <textarea
        {...inputProps}
        className={classnames(
          input,
          nonTrailingIconInput,
          disabled && styles.disabledInput
        )}
        disabled={disabled}
        id={inputProps.id}
      />
    </InputContainer>
  )
}

export const DropdownSelect = forwardRef(
  (props: SelectProps, ref?: React.Ref<HTMLSelectElement>) => {
    const icon = <IconAngleDown />
    const { children, disabled, ...inputProps } = props
    if (inputProps) {
      // avoid invalid attribute on select
      delete inputProps.label
      delete inputProps.containerClassName
    }
    return (
      <InputContainer {...props} icon={icon}>
        <select
          {...inputProps}
          className={classnames(
            input,
            nonTrailingIconInput,
            disabled && styles.disabledInput
          )}
          onBlur={props.onChange}
          disabled={disabled}
          id={inputProps.id}
          ref={ref}
        >
          {children}
        </select>
      </InputContainer>
    )
  }
)

interface SearchInputProps {
  onReset: () => void
}

export function SearchInput (props: InputProps & SearchInputProps) {
  const [searchInputValue, setSearchInputValue] = useState('')
  const {
    className = '',
    disabled,
    icon,
    onReset,
    placeholder,
    ...inputProps
  } = props

  return (
    <div
      {...props}
      id={inputProps.id}
      className={classnames(
        className,
        styles.searchInputContainer,
        searchInputValue ? styles.inputHasValue : ''
      )}
    >
      <input
        aria-label='Search'
        {...inputProps}
        className={classnames(
          input,
          searchInput,
          nonTrailingIconInput,
          disabled && styles.disabledInput,
          className
        )}
        disabled={disabled}
        id={inputProps.id}
        placeholder={placeholder}
        onChange={e => {
          setSearchInputValue(e.target.value)
        }}
      />
      <button
        aria-label='Reset'
        className={styles.resetInputButton}
        type='reset'
        onClick={() => {
          onReset()
          setSearchInputValue('')
        }}
      >
        <IconClose />
      </button>
      <button
        aria-label='Search'
        className={styles.searchInputButton}
        type='submit'
      >
        <IconSearch />
      </button>
    </div>
  )
}

export function PhoneInput (props: InputProps) {
  const { icon, ...inputProps } = props

  if (inputProps) {
    // avoid invalid attribute on select
    delete inputProps.containerClassName
  }

  return (
    <InputContainer {...props} icon={icon}>
      <PhoneNumberInput
        {...(inputProps as any)}
        className={input}
        id={inputProps.id}
      />
    </InputContainer>
  )
}

export function ValidationError ({
  message,
  style
}: {
  message?: string
  style?: React.CSSProperties
}) {
  if (!message) {
    return null
  }

  return (
    <div className={styles.error} style={style} data-qa='validation-error'>
      {message}
    </div>
  )
}

export default Input
