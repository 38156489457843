export default function getEnvVariable (name: string): string {
  const localStorageValue = (global as any).localStorage
    ? (global as any).localStorage.getItem(name)
    : ''
  if (localStorageValue) {
    return localStorageValue
  }
  switch (name) {
    case 'ATHENA_ENDPOINT':
      return process.env.ATHENA_ENDPOINT || ''
    case 'API_ENDPOINT':
      return process.env.API_ENDPOINT || ''
    case 'HADES_CLIENT_ID':
      return process.env.HADES_CLIENT_ID || ''
    case 'HADES_ENDPOINT':
      return process.env.HADES_ENDPOINT || ''
  }
  return ''
}
