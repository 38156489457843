// @flow
import type {
  ErrorCode,
  FullResponse,
  ErrorResponse,
  JsonApiError
} from '../../types/JsonApi'

export const NOT_FOUND: ErrorCode = 'record_not_found'
export const ATHENA_ERROR: ErrorCode = '__athena_error__'
export const NONJSONAPI_ERROR: ErrorCode = '__nonjsonapi_error__'

export const errorCodes = {
  NOT_FOUND,
  ATHENA_ERROR,
  NONJSONAPI_ERROR
}

type NonJsonApiErrorResponse = {|
  message: string
|}

export function unwrapError (
  errorResponse: FullResponse<ErrorResponse> | NonJsonApiErrorResponse
): ErrorResponse {
  if (errorResponse.response && errorResponse.response.data) {
    if (Array.isArray(errorResponse.response.data.errors)) {
      return errorResponse.response.data
    }
    return {
      errors: []
    }
  }
  return {
    errors: [
      {
        code: errorCodes.NONJSONAPI_ERROR,
        detail: errorResponse.message || JSON.stringify(errorResponse),
        status: '',
        title: 'Non-JSON API error'
      }
    ]
  }
}
