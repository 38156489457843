import { GroupedRole, Role } from '../../types/Role'

export const ADMIN: Role = 'admin'
export const DISPATCHER: Role = 'dispatcher'
export const DRIVER: Role = 'driver'
export const PROVIDER: Role = 'provider'
export const REVIEWER: Role = 'reviewer'

// pseudo-roles, they do not exist in the backend, we just set them based on the onboarding_status field from /me
export const ONBOARDING: GroupedRole = 'ONBOARDING'
export const NOT_ONBOARDING: GroupedRole = 'NOT_ONBOARDING'

export const LOGGED_OUT: GroupedRole = 'LOGGED_OUT'
export const LOGGED_IN: GroupedRole = 'LOGGED_IN'
