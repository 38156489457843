import { datadogRum } from './datadog'

export function trackError (error: string | Error, cause?: any) {
  if (typeof error === 'string') {
    error = new Error(error + (cause ? `: ${cause.message}` : ''))

    // @ts-ignore
    error.cause = cause
  }

  console.error(error)
  datadogRum()?.addError(error)
}
