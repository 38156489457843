import { MessageDescriptor } from 'react-intl'

// @ts-ignore
import { Action, TypedAction } from '../../types/Action'
import { Entity } from '../../types/Entity'
import {
  ApiFailureResponse,
  ApiSuccessResponse,
  ErrorCode,
  ErrorResponse,
  ItemResponse,
  JsonApiError,
  ListResponse
} from '../../types/JsonApi'
import { WithUuid } from 'types/Item'
// $FlowFixMe TS import
import { makeUuid } from '../helpers/makeUuid'
import { useDispatch } from 'react-redux'

// Constants
export const ENTITY: Entity = 'flash_message'
export const MessageTypeAlert: 'alert' = 'alert'
export const MessageTypeFailure: 'failure' = 'failure'
export const MessageTypeSuccess: 'success' = 'success'

export type MessageType =
  | typeof MessageTypeAlert
  | typeof MessageTypeFailure
  | typeof MessageTypeSuccess

export interface MessageDescriptorWithValues extends MessageDescriptor {
  values?: {}
}

export type FlashMessages<T> = {
  success: (
    payload: ItemResponse<T> | ListResponse,
    options: {}
  ) => MessageDescriptorWithValues[]
  failure: (
    payload: ErrorResponse,
    options?: {}
  ) => MessageDescriptorWithValues[]
}

// Actions
export const actions = {
  ADD_FLASH_MESSAGE: `${ENTITY}/ADD_FLASH_MESSAGE`,
  REMOVE_FLASH_MESSAGE: `${ENTITY}/REMOVE_FLASH_MESSAGE`
}

// Model and initial state
export const Model: {
  uuid?: string
  message: MessageDescriptorWithValues
  messageType: MessageType | ''
} = {
  uuid: '',
  message: {
    id: ''
  },
  messageType: ''
}
export const initialState: Array<typeof Model> = []

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  { type, payload }: { type: string; payload: typeof Model }
) {
  switch (type) {
    case actions.ADD_FLASH_MESSAGE:
      const uuid = payload.uuid || makeUuid()
      const newPayload = { ...payload, uuid }
      return [...state, newPayload]
    case actions.REMOVE_FLASH_MESSAGE:
      return state.filter(item => item.uuid !== payload.uuid)
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  addFlashMessage (
    messageType: MessageType,
    message: MessageDescriptorWithValues,
    uuid?: string
  ) {
    scrollToTop()
    return {
      type: actions.ADD_FLASH_MESSAGE,
      error: messageType === MessageTypeFailure,
      payload: {
        messageType,
        message,
        uuid
      },
      meta: {}
    }
  },
  addFlashMessages (options: ApiFailureResponse | ApiSuccessResponse): void {
    const {
      apiAction,
      dispatch,
      entityName,
      flashMessages,
      payload,
      requestPayload
    } = options
    if (!flashMessages) {
      return
    }
    const callbackOptions = {
      ...requestPayload,
      apiAction,
      entityName
    }
    let messageDescriptors = []
    let messageType: MessageType
    if (payload.data) {
      messageDescriptors = flashMessages.success(payload, callbackOptions)
      messageType = MessageTypeSuccess
    } else if (payload.errors) {
      messageDescriptors = flashMessages.failure(payload, callbackOptions)
      messageType = MessageTypeFailure
    }
    scrollToTop()

    messageDescriptors
      .map((messageDescriptor: MessageDescriptor) =>
        actionCreators.addFlashMessage(messageType, messageDescriptor)
      )
      .forEach(dispatch)
  },
  removeFlashMessage (uuid: string): Action {
    return {
      type: actions.REMOVE_FLASH_MESSAGE,
      error: false,
      payload: {
        uuid
      },
      meta: {}
    }
  }
}

// Side effects
export function getSuccessFlashMessages (
  id: string,
  values?: WithUuid
): MessageDescriptorWithValues[] {
  return [
    {
      id,
      values
    }
  ]
}

export function useDispatchFlashMessage () {
  const dispatch = useDispatch()

  return (messageType: MessageType, messageId: string, values?: object) => {
    dispatch(
      actionCreators.addFlashMessage(messageType, { id: messageId, values })
    )
  }
}

function getErrorCode (error: JsonApiError) {
  let code = error.code || error.title
  if (
    error.code === 'validation_error' &&
    error.source &&
    error.source.pointer
  ) {
    code = `validation_error.${error.source.pointer.replace(
      '/data/attributes/',
      ''
    )}` as ErrorCode
  }
  return code
}

export function getFailureFlashMessages (
  id: string,
  payload: ErrorResponse
): MessageDescriptorWithValues[] {
  return payload.errors.map(error => ({
    id: `${id}.${getErrorCode(error)}`,
    values: error.meta
  }))
}

function scrollToTop () {
  try {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  } catch (e) {
    window.scrollTo(0, 0)
  }
}
