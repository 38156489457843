import { ThunkAction } from 'redux-thunk'
import { ActionType, createStandardAction, getType } from 'typesafe-actions'

// @ts-ignore
import { setEntityModel } from '../api/apiClient'
// @ts-ignore
import apiRequest, {
  dispatchSuccessActions,
  dispatchFailureActions
  // @ts-ignore
} from '../api/request'
import { actionCreators as loginActionCreators, LoginActions } from '../login'
import { Entity } from 'types/Entity'
import { ErrorResponse } from 'types/JsonApi'
import { MeAsApiResponse, Me } from 'types/Me'
import { LSP } from 'types/LSP'
// @ts-ignore
import { State as StoreState } from '../store/reducer'
import { useSelector } from 'react-redux'
import { constructApiUrl, sendRequest } from '../api/useFetch'
import { OnboardingStatus } from '../onboarding/overview/onboardingData'
import { trackError } from '../tracking/error'

// Types
export interface State extends Me {
  lsp: LSP
  isFetching: boolean
  hasFetched: boolean
}

// Constants
export const ENTITY: Entity = 'me'

// Model and initial state
export const Model: MeAsApiResponse = {
  email: '',
  firstName: '',
  id: '',
  intercomUserHash: '',
  lastName: '',
  title: 'unknown',
  lsp: {
    onboardingStatus: OnboardingStatus.Completed,
    applicantType: null
  },
  mobile: '',
  roles: [],
  state: 'live'
}
export const initialState: State = {
  ...Model,
  isFetching: false,
  hasFetched: false
}

// Action creators
const actionCreatorsSync = {
  loadMeRequest: createStandardAction('LOAD_ME_REQUEST').map(() => ({
    payload: {},
    meta: {},
    error: false
  })),
  loadMeSuccess: createStandardAction('LOAD_ME_ITEM_SUCCESS').map(
    ({ result: payload }: { result: MeAsApiResponse }) => ({
      payload,
      meta: {},
      error: false
    })
  ),
  loadMeFailure: createStandardAction('LOAD_ME_ITEM_FAILURE').map(
    ({ errors: payload }: ErrorResponse) => ({
      payload,
      meta: {},
      error: true
    })
  )
}
export type MeActions = ActionType<typeof actionCreatorsSync>
type ThunkResult<R> = ThunkAction<R, { me: State }, unknown, MeActions>
export const actionCreatorsAsync = {
  loadMe: (): ThunkResult<void> => async dispatch => {
    dispatch(actionCreatorsSync.loadMeRequest())

    try {
      const response = await sendRequest(constructApiUrl('/me'))
      const me = await response.json()
      return dispatch(actionCreatorsSync.loadMeSuccess({ result: me }))
    } catch (e) {
      trackError('Failed to load me', e)
      return dispatch(actionCreatorsSync.loadMeFailure({ errors: [e as any] }))
    }
  }
}
export const actionCreators = { ...actionCreatorsSync, ...actionCreatorsAsync }

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  action: MeActions | LoginActions
) {
  switch (action.type) {
    case getType(actionCreators.loadMeRequest):
      return {
        ...state,
        isFetching: true
      }
    case getType(actionCreators.loadMeSuccess):
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        ...action.payload
      }
    case getType(actionCreators.loadMeFailure):
      return {
        ...state,
        hasFetched: true,
        isFetching: false
      }
    case getType(loginActionCreators.logoutAction):
    case getType(loginActionCreators.sessionExpiredAction):
      return {
        ...initialState
      }
    default:
      return state
  }
}

// Side effects
setEntityModel(ENTITY, Model, {
  collectionPath: 'me'
})

export function useMe (): State {
  return useSelector<StoreState>(state => state.me) as State
}
