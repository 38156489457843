// @flow
import { omit, set, setIn, update, updateIn } from 'timm'

import { handleGetRequest, handleResponse } from './helpers/replaceAttribute'
import type { RequestResponseMiddleware } from '../../../types/JsonApi'

export const flightDesignatorRes: RequestResponseMiddleware = {
  name: 'flightDesignatorRes',
  res: function flightDesignatorRes (payload) {
    return handleResponse(payload, {
      from: 'flight_number',
      to: 'flight_designator',
      value: value => value
    })
  }
}

export const flightDesignatorReq: RequestResponseMiddleware = {
  name: 'flightDesignatorReq',
  req: function flightDesignatorReq (payload) {
    return handleGetRequest(payload, {
      from: 'flight_designator',
      to: 'flight_number',
      value: value => value
    })
  }
}
