import { useIntl } from 'react-intl'
import { trackError } from '../tracking/error'

export function useT () {
  const intl = useIntl()
  return (id: string, values?: any, fallbackId?: string) => {
    const message = intl.$t({ id }, values)

    if (message === id) {
      trackError(`Missing translation for key ${id}`)

      if (fallbackId) {
        return intl.$t({ id: fallbackId }, values)
      }
    }

    return message
  }
}
